import Vue from 'vue'
import App from './App.vue'
// import Chat from '/Users/mopeiji/Desktop/test/vue-beautiful-chat/dist/vue-beautiful-chat.umd.min.js'
import Chat from '@/common/vue-beautiful-chat.umd.min.js'
Vue.use(Chat)
Vue.config.productionTip = false
import i18n from "./i18n"
import store from './store'
// import amplitude from "amplitude-js"
// amplitude.init('b9400629b7afd025f48fa4d5a815037e')
// Vue.prototype.$amplitude = amplitude
import router from './router'; // Import the router

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
