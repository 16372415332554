import request from '@/utils/axios'

// 获取聊天记录
export function getService(data) {
 return request({
  url: '/v1/try/help/service',
  method: 'POST',
  data
 })
}
